import React from "react";
import Layouts from "../../components/Layouts";
import Seo from "../../components/Seo";
import { PageHeader } from "../../components/Atomics/PageHeader";
import { Cta } from "../../components/Cta";
import { NewsFeed } from "../../components/Screens/News/NewsFeed";
import { ContactBox } from "../../components/Screens/News/ContactBox";
import { graphql } from "gatsby";

const NewsPageArabic = ({ data }) => {
	const latestNews = data.page.newsAr.latestNewsAr.news || [];
	const featuredNews = data.page.newsAr.featuredNewsAr.news || [];

	return (
		<>
			<Seo title="الاخبار" />

			<Layouts lang={"ar"} dir="rtl">
				<PageHeader miniHeader="الأخبار" header="آخر أخبار كاشات" />
				<NewsFeed
					featuredTitle="مميزة"
					featuredNews={featuredNews}
					latestNews={latestNews}
					latestNewsTitle="آخر أخبار كاشات"
				/>
				<ContactBox
					miniHeader={data.page.mediaKitNewsAr.mediaKitBannerAr.miniHeader}
					header={data.page.mediaKitNewsAr.mediaKitBannerAr.header}
					text={data.page.mediaKitNewsAr.mediaKitBannerAr.text}
					buttonText={data.page.mediaKitNewsAr.mediaKitBannerAr.buttonText}
					buttonLink={data.page.mediaKitNewsAr.mediaKitBannerAr.buttonLink}
				/>
				<Cta
					lang="ar"
					miniHeader="أول مقدم للخدمات المالية متناهية الصغر في الشرق الأوسط"
					header="مهمتنا تمكين الوصول الى الإمكانيات المالية الرقمية"
					subHeader="كاشات هي أول خطوة للشمول المالي للمصريين الغير متعاملين مع البنوك عن طريق توفير تمويلات انتاجية قصيرة المدى وسريعة"
				/>
			</Layouts>
		</>
	);
};
export default NewsPageArabic;
export const pageQu = graphql`
	query ArabicNews {
		page: wpPage(databaseId: { eq: 297 }) {
			title
			mediaKitNewsAr {
				mediaKitBannerAr {
					miniHeader
					header
					text
					buttonText
					buttonLink
				}
			}
			newsAr {
				featuredNewsAr {
					news {
						image {
							localFile {
								childImageSharp {
									gatsbyImageData(width: 1920, placeholder: BLURRED)
								}
							}
						}
						title
						text
						publisherLogo {
							sourceUrl
						}
						publisherName
						link
					}
				}
				latestNewsAr {
					news {
						image {
							localFile {
								childImageSharp {
									gatsbyImageData(width: 1920, placeholder: BLURRED)
								}
							}
						}
						title
						text
						publisherLogo {
							sourceUrl
						}
						publisherName
						link
					}
				}
			}
		}
	}
`;
